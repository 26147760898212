import React, { FC } from 'react';
import { Props, Styles } from 'react-modal';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { DynamicImage, Modal, Text } from 'components';
import PaymentDialogOrderSummary from './OldPaymentDialogOrderSummary';
import PaymentsForm from './OldPaymentsForm';
import EmailInput from './OldEmailInput';
import { AppState } from 'state/types';
import PaymentSteps from './OldPaymentSteps';
import SecureFooter from 'assets/icons/payments/secure-footer.svg';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { useQuizData } from 'utils/hooks';
import { OrderSummarySubItem } from 'types/checkout';
import { Product } from 'types/product';
import PaymentStepsSVG from 'assets/images/checkout/payment-steps.svg';
import Chevron from 'assets/icons/back-arrow.svg';
import PaymentDialogOrderSummaryNew from './OldPaymentDialogOrderSummaryNew';

interface PaymentDialogProps {
  selectedProduct: Product | null;
  onSuccess: (data: any) => Promise<void>;
  orderSummarySubItems?: OrderSummarySubItem[];
  onClose?: () => void;
  testTitle?: string;
}

const ContentContainer = styled.div`
  padding: 2rem 1rem 0;
  max-width: 46.375rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${tablet} {
    padding: 1.5rem 1rem 1rem 1rem;
  }
`;

const PaymentStepsContainer = styled.div`
  max-width: 15.75rem;
  margin: 0 auto;
`;

const TitleContainer = styled.div`
  text-align: center;
  padding: 1.5rem 0;
`;

const SecureFooterStyled = styled(SecureFooter)`
  margin-top: 1rem;
  width: 100%;
`;

const EmailInputContainer = styled.div`
  padding-bottom: 0.375rem;
`;

const SplitContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;

  @media ${tablet} {
    flex-direction: column-reverse;
  }
`;

const BackBtnContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  gap: 0.5rem;
`;

const BackBtnText = styled(Text)`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 141.957%;
  padding-top: 0.1rem;
`;

const MethodsSVGStyled = styled(DynamicImage)`
  margin-top: 1.5rem;
`;

const TestTitle = styled.p`
  color: #000;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.02rem;
  padding: 0 0 1.25rem;
  @media ${mobile} {
    padding: 0 0 1rem;
  }
`;

const PaymentDialog: FC<PaymentDialogProps & Props> = ({
  selectedProduct,
  onSuccess,
  orderSummarySubItems,
  testTitle,
  onClose,
  onRequestClose,
  ...props
}) => {
  const { user } = useSelector((state: AppState) => state.user);

  const data = useQuizData('payments');

  const { isMobile, isTablet } = useQuery();

  const customStyle: Styles = {
    content: {
      padding: 0,
    },
  };

  const mobileStyle: Styles = {
    content: {
      padding: 0,
      width: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      height: '100%',
      borderRadius: 0,
    },
  };

  return (
    <ContentContainer>
      {/* <PaymentStepsContainer>
        <PaymentSteps
          firstStepTitle={data?.firstStepTitle}
          secondStepTitle={data?.secondStepTitle}
          thirdStepTitle={data?.thirdStepTitle}
        />
      </PaymentStepsContainer> */}
      {/* <PaymentStepsSVG width={isTablet ? 305 : '100%'} /> */}
      <BackBtnContainer onClick={onRequestClose}>
        <Chevron />
        <BackBtnText>Back</BackBtnText>
      </BackBtnContainer>
      <Container>
        <PaymentsForm onSuccess={onSuccess} />
        <PaymentDialogOrderSummary selectedProduct={selectedProduct} />
      </Container>
      {/* <PaymentOptions alt="payment options" src={'payments/paymentImage.png'} /> */}
    </ContentContainer>
  );
};

export default PaymentDialog;

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 1.5rem;
  padding-top: 1.75rem;
  @media ${tablet} {
    flex-direction: column-reverse;
    align-items: center;
    gap: 1rem;
    padding-top: 1rem;
  }
`;
