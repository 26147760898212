import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { KiloPayments, Loader } from 'components';
import { AppState } from 'state/types';

import Cards from 'assets/icons/cards.svg';
import { tablet } from 'styles/breakpoints';

interface PaymentsFormProps {
  onSuccess: (data: any) => Promise<void>;
}

const Container = styled.div`
  width: 100%;
  min-height: 10.66rem;
  max-width: 21.4375rem;
  flex-shrink: 0;
  @media ${tablet} {
    max-width: 24.563rem;
  }
`;

const LoaderStyled = styled(Loader)`
  height: 10.66rem;
`;

const SafeCheckout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
  margin-top: 1.5rem;

  p {
    color: #8d8d8d;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 0.9375rem */
    letter-spacing: -0.00688rem;

    b {
      font-weight: 500;
    }
  }

  @media ${tablet} {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
`;

const PaymentsForm: FC<PaymentsFormProps> = ({ onSuccess, ...props }) => {
  const { code, selected_plan } = useSelector((s: AppState) => s.user);

  if (!selected_plan) {
    return null;
  }
  return (
    <Container {...props}>
      <KiloPayments
        code={code}
        initialProduct={selected_plan}
        handleError={console.error}
        handleSuccess={onSuccess}
      />
      <SafeCheckout>
        <p>
          GUARANTEED <b>SAFE CHECKOUT</b>
        </p>
        <Cards />
      </SafeCheckout>
    </Container>
  );
};

export default PaymentsForm;
